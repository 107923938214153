import { type PaymentStatus, type RaceStatus, type SubRaceStatus } from "common"
import { type RegistrationPriceInfo } from "common/prices"
import { yup } from "utils/translated-yup"

export interface TransferRegistrationInfo {
  nameorteam: string
  raceid: number
  racename: string
  racestatus: RaceStatus
  raceslug: string
  subracename: string
  subracestatus: SubRaceStatus
  paymentstatus: PaymentStatus
  priceinfo: RegistrationPriceInfo
}

export interface TransferRegistrationApprovalOkInfo extends TransferRegistrationInfo {
  type: "ok"
}

export interface TransferRegistrationApprovalExpiredInfo extends Pick<TransferRegistrationInfo, "nameorteam" | "racename" | "subracename" | "raceslug"> {
  type: "expired"
}

export interface TransferRegistrationApprovalAlreadyDoneInfo extends Pick<TransferRegistrationInfo, "nameorteam" | "racename" | "subracename" | "raceslug"> {
  type: "already-done"
}

export type TransferRegistrationApprovalInfo = TransferRegistrationApprovalOkInfo | TransferRegistrationApprovalExpiredInfo | TransferRegistrationApprovalAlreadyDoneInfo

export interface TransferRegistrationCompletedInfo extends TransferRegistrationInfo {
  regguid: string
  racerguid: string
  token: string
}

export interface TransferRegistrationRequest {
  email: string
  regguid: string
  racerguid: string
  token: string
  consent: boolean
}

export const transferRegistrationRequestSchema = yup.object<TransferRegistrationRequest>().shape({
  email: yup.string().email().required().label("Email"),
  regguid: yup.string().required().label("ID registrace"),
  racerguid: yup.string().required().label("ID závodníka"),
  token: yup.string().required().label("Token"),
  consent: yup.boolean().required().oneOf([true], "Pro pokračování musíte souhlasit s podmínkami").label("Souhlas s podmínkami")
})

export interface TransferRegistrationResponse {
  status: "OK" | "FailedToSend" | "AlreadySent" | "CommonError" | "Expired" | "AlreadyTransferred"
  email?: string
}

export const transferRegistrationDeadlineInDays = 3

export interface TransferRegistrationApprovalResponse {
  status: "OK" | "Expired" | "NotFound"
}
